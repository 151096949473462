body{
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    background: #34495e;

}
.box{
    width: 300px;
    padding: 40px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #191919;
    text-align: center;
}
.box h1{
    color: beige;
    
}
.box input{
    border: 0;
    background: none;
    display: block;
    margin: 20px auto;
    text-align: center;
    border: 2px solid #3498db;
    padding: 14px 10px;
    width: 200px;
    outline: none;
    color: white;
    border-radius: 24px;
    transition: 0.25s;
}
.box input:focus{
    width: 280px;
    border-color: #2ecc71;
}
.box button[name ="si"] {
    border: 0;
    background: none;
    display: block;
    margin: 20px auto;
    text-align: center;
    border: 2px solid #2ecc71;
    padding: 14px 40px;
    cursor: pointer;
    outline: none;
    color: white;
    border-radius: 24px;
    transition: 0.25s;
}
 .box button[name="su"]{

    border: 0;
    background: none;
    display: block;
    margin: 20px auto;
    text-align: center;
    border: 2px solid #3498db;
    padding: 14px 40px;
    cursor: pointer;
    outline: none;
    color: white;
    border-radius: 24px;
    transition: 0.25s;

}
.box button[name="su"]:hover{
    background: #2ecc71;
    border: 2px solid #2ecc71;
}
.box button[name="si"]:hover{
    background: #3498db;
    border: 2px solid #3498db;
}

