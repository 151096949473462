*{
  box-sizing: border-box;

}

body{
  font-family: sans-serif;
  margin: 0;
  display: grid;
  grid-template-columns: 30% auto;
  grid-template-rows: 60px auto 60px;
  grid-template-areas: "hd hd"
                       "side nun"
                       "footer footer" ;
}