*{
  box-sizing: border-box;
  margin: 0;
}
body{
  margin: 0;
}

.App{
  font-family: sans-serif;
  margin: 0;
  display: grid;
  grid-template-columns: 30% auto;
  grid-template-rows: 60px auto 60px;
  grid-template-areas: "hd hd"
                       "side nun"
                       "footer footer" ;
}
.head .side .nun .footer{
  padding: 16px;
  text-align: center;
  

}
.head{
  background: #521751;
  grid-area: hd;
}
.side{
  background: #fa923f;
  grid-area: side;
}
.nun{
  grid-area: nun;
}
.footer{
  background: black;
  grid-area: footer;
}